<template>
  <v-container class="contact-form">
    <Breadcrumb :items="breadcrumb" :key="category.categoryId" />
    <category-title v-if="category" :category="category" :showSub="true" />

    <v-form class="d-flex flex-wrap" ref="form">
      <v-row no-gutters>
        <!-- TICKET TYPE -->
        <v-col cols="12">
          <v-select
            v-if="ticketItems"
            v-model="formData.ticketType"
            :placeholder="`${$t('contact.requestInformation')} *`"
            :items="ticketItems"
            item-text="text"
            item-value="value"
            :rules="[requiredRules()]"
            required
            single-line
            dense
            outlined
          ></v-select>
        </v-col>
        <!-- FIRST NAME -->
        <v-col class="pr-sm-2" cols="12" sm="6">
          <v-text-field
            v-model="formData.firstName"
            :placeholder="`${$t('contact.name')} *`"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- LAST NAME -->
        <v-col class="pl-sm-2" cols="12" sm="6">
          <v-text-field
            v-model="formData.lastName"
            :placeholder="`${$t('contact.surname')} *`"
            :rules="[requiredRules()]"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- WAREHOUSE -->
        <v-col cols="12">
          <v-autocomplete
            append-icon=" "
            v-model="formData.warehouse"
            :placeholder="$t('contact.market')"
            return-object
            :items="warehouses"
            item-value="warehouseId"
            item-text="name"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <!-- EMAIL -->
        <v-col class="pr-sm-2 pr-md-0 pr-md-2" cols="12" sm="6">
          <v-text-field
            v-model="formData.email"
            :placeholder="`${$t('contact.email')} *`"
            :rules="emailRules"
            :error-messages="errors"
            type="email"
            autocomplete="off"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- PREFIX-->
        <v-col class="pl-md-2" cols="2" sm="1">
          <v-text-field
            v-model="formData.prefix"
            class="text-center"
            placeholder="+39"
            disabled
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- PHONE NUMBER -->
        <v-col cols="10" sm="5">
          <v-text-field
            v-model="formData.phoneNumber"
            :placeholder="`${$t('contact.phoneNumber')} *`"
            :rules="mobilePhoneRules"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- MESSAGE -->
        <v-col class="mb-3" cols="12">
          <v-textarea
            v-model="formData.description"
            :rules="[requiredRules()]"
            :placeholder="`${$t('contact.message')} *`"
            required
            outlined
          >
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <h5 class="text-uppercase">
            {{ $t("contact.informativa.title") }}
          </h5>
        </v-col>
        <!-- PRIVACY -->
        <v-col class="py-0" cols="12">
          <v-checkbox
            :rules="checkboxRule"
            required
            v-model="formData.acceptPrivacyPolicies"
          >
            <template v-slot:label>
              <div>
                {{ $t("contact.informativa.privacy") }}
                <a
                  class="ml-n1"
                  target="_system"
                  href="https://laspesaonline.eurospin.it/p/wp-content/uploads/2023/04/eurospin_informativa_contatti_1_2023.pdf"
                  @click="clicked"
                >
                  {{ $t("contact.privacyLink") }}
                </a>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <!-- MARKETING POLICY -->
        <v-col class="py-0" cols="12">
          <v-checkbox v-model="formData.acceptMarketingPolicies">
            <template v-slot:label>
              <div>
                {{ $t("contact.informativa.marketing") }}
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <!-- PROFILING POLICY -->
        <v-col class="py-0" cols="12">
          <v-checkbox v-model="formData.acceptProfilingPolicies">
            <template v-slot:label>
              <div>
                {{ $t("contact.informativa.profiling") }}
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <!-- CTA -->
        <v-col class="mt-3" cols="12">
          <v-btn color="primary" @click="validate()" :loading="loading">
            {{ $t("contact.sendFormData") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<style lang="scss">
.contact-form {
  article {
    max-width: 900px;
  }
  h1 {
    font-size: 18px;
  }
  form {
    .v-input.v-input--checkbox .v-input__slot {
      display: flex;
      align-items: flex-start;
      label {
        margin: auto 0px;
      }
    }
    .row div:nth-child(6) {
      .v-input__slot {
        padding: 0 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        fieldset {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        input {
          text-align: center;
        }
      }
    }
    .row div:nth-child(7) {
      .v-input__slot {
        fieldset {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  form .v-input .v-input__slot {
    align-items: center;
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isMobilePhone
} from "~/validator/validationRules";

import addressService from "~/service/addressService";
import eurospinCustomService from "@/service/eurospinCustomService";
import cloneDeep from "lodash/cloneDeep";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ContactForm",
  mixins: [clickHandler, categoryMixins],
  components: {
    Breadcrumb,
    CategoryTitle
  },
  data() {
    return {
      errors: [],
      loading: false,
      formData: {
        ticketType: null,
        firstName: null,
        lastName: null,
        warehouse: null,
        email: null,
        prefix: null,
        phoneNumber: null,
        description: null,
        acceptPrivacyPolicies: null,
        acceptMarketingPolicies: false,
        acceptProfilingPolicies: false
      },
      warehouses: null,
      ticketItems: [
        {
          text: "Richiesta di informazioni",
          value: "Informazioni Spesa Online"
        },
        { text: "Pagamento e ordini", value: "Situazione Spesa Online" },
        { text: "Reclami e segnalazioni", value: "Reclami Spesa Online" },
        { text: "Altro", value: "Informazioni Spesa Online " }
      ],

      requiredRules: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      mobilePhoneRules: [
        requiredValue("Digitare il numero di cellulare"),
        isMobilePhone()
      ]
    };
  },
  computed: {
    checkboxRule() {
      return [
        this.formData.acceptPrivacyPolicies === true || "Campo obbligatorio"
      ];
    }
  },
  methods: {
    validate() {
      console.log("clickeeeed");
      let _this = this;

      console.log("FORM DATA:", _this.formData);
      if (_this.$refs.form) {
        _this.valid = _this.$refs.form.validate();
        if (_this.valid) {
          _this.loading = true;
          const formData = cloneDeep(_this.formData);

          if (formData.warehouse) {
            const warehouseId = formData.warehouse?.warehouseId;
            if (warehouseId) {
              formData.warehouse = warehouseId;
            }
          }
          formData.prefix = "0039";

          const formDataPhoneNumber = formData.phoneNumber.trim();
          formData.phoneNumber = formDataPhoneNumber;

          const formDataEmail = formData.email.trim();
          formData.email = formDataEmail;

          eurospinCustomService
            .submitTicket(formData)

            .then(function(data) {
              if (data) {
                console.log(data);
                if (data.response.status == 0) {
                  _this.loading = false;
                  _this.$router.push({
                    name: "ThankyouContactUs",
                    params: { communication: false }
                  });
                } else {
                  _this.$refs.form.reset();
                  _this.loading = false;
                }
              }
              console.log(data);
            });
        } else {
          const invalidFields = _this.$refs.form.$children.filter(
            field => !field.valid && field.type !== "button"
          );
          invalidFields[0].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          invalidFields[0].$el.focus();
        }
      }
    },
    async findWarehouse() {
      const res = await addressService.findWarehouse();
      this.warehouses = res.warehouses.map(wh => ({
        warehouseId: wh.warehouseId,
        name: `${wh.address.addressName}, ${wh.address.address1}`
      }));
    }
  },
  created() {
    this.findWarehouse();
  }
};
</script>
